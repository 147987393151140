import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GragmentServiceService {

  private currentFragment = '';

  constructor(private router: Router) {
    this.router.events.pipe(filter( (event:any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const fragment = event.url.split('#')[1];
      this.currentFragment = fragment || '';
    });
  }

  getCurrentFragment(): string {
    return this.currentFragment;
  }

  scrollToFragment(fragment: string): void {
    const element = document.querySelector('#' + fragment) as HTMLElement;
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const offset = elementRect.top + scrollTop - this.getOffset(element);
  
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
  
      this.currentFragment = fragment; // Update the current fragment
      const urlTree = this.router.parseUrl(this.router.url);
      urlTree.fragment = fragment; // Update the URL fragment
      this.router.navigateByUrl(urlTree); // Navigate to the updated URL
    }
  }

  getOffset(element: HTMLElement): number {
    const style = window.getComputedStyle(element);
    const marginTop = parseInt(style.marginTop, 10);
    const paddingTop = parseInt(style.paddingTop, 10);
  
    // Adjust these values as needed for your specific layout
    const fixedHeaderHeight = 60;
    const additionalSpacing = 20;
  
    return marginTop + paddingTop + fixedHeaderHeight + additionalSpacing;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-aboutus2',
  templateUrl: './aboutus2.component.html',
  styleUrls: ['./aboutus2.component.scss']
})
export class Aboutus2Component {

}

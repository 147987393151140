 <section>
 <footer class="footer-area pt-70">
     <div class="row justify-content-between mb-4 footer-robot">
        <div class="col-md-5 text-left align-self-left address">
          <h2>Corporate Headquarters</h2>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 512" width="24" height="24" style="fill:white">
         <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
            </svg>
                    15851  Dallas Pkwy Ste 306 #346 <br />
            Addison, TX 75001
          </p>
        </div>
        <div class="col-md-5 text-left">
          <h2 class="connectus">Connect with us</h2>
          <div class="icon-container">
            <div class="icons" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 5px; margin-left:10%;">
              <!-- LinkedIn icon -->
              <a
                class="social-icon"
                href="https://www.linkedin.com/company/securebridgeio/"
                style="margin-right: 1%;"
              >
                <!-- SVG code for LinkedIn icon -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="fill: white">
                  <path d="M416 32h21.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                </svg>
              </a>
              <!-- Twitter icon -->
              <a
                class="social-icon"
                href="https://twitter.com/securebridge_io"
                style="margin-right: 1%;"
              >
                <!-- SVG code for Twitter icon -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="fill: white">
                    <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"/>
                    </svg>
              </a>
              <!-- Email icon -->
              <a
                class="social-icon"
                href="mailto:info@securebridge.io"
                style="margin-right: 1%;"
              >
                <!-- SVG code for Email icon -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill: white">
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                </svg>
              </a>
              <div class="text-white emailtext">info@securebridge.io</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center footer-robot">
        <div class="col-md-12 text-center">
          <p class="copy">© 2023 SecureBridge. All Rights Reserved.</p>
        </div>
      </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>

</section>

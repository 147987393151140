<ng-container *ngIf="showServices2">
<section class="services">
    <div class="container">
        <div class="row justify-around ">
            <div class="col-lg-12 col-md-6">
                <h1>Our Services</h1>
                <p class="title">
                    Our extensive services are delivered by professionals
                    committed to achieving exceptional outcomes, and they are
                    completely customized to meet your requirements.
                </p>

                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 s">
                        <div class="card service-card">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <h3>Endpoint Management</h3>
                                </li>
                                <li class="list-group-item-img">
                                    <img class="icon-service img-fluid" src="../../../../assets/images/endpoint.png" alt="Endpoint"/>
                                </li>
                                <li class="list-group-item-content">
                                    <p>
                                        We guarantee device safety, compliance, and up-to-date adherence to industry standards using our expertise and advanced technology, enabling seamless business performance in a rapidly evolving digital world.
                                    </p>
                                    <button class="box-btn s-b" (click)="navigateToMoreInfo()">Know More</button>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="card service-card">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <h3>Identity & Access</h3>
                                </li>
                                <li class="list-group-item-img">
                                    <img class="icon-service img-fluid" src="../../../../assets/images/identity.png" alt="Identity"/>
                                </li>
                                <li class="list-group-item-content">
                                    <p>
                                        We help you strike the perfect balance
                                                                                                                                                                                                        between security and usability with
                                                                                                                                                                                                        efficient IAM strategies, safeguarding
                                                                                                                                                                                                        against unauthorized access while
                                                                                                                                                                                                        boosting operational efficiency and user
                                                                                                                                                                                                        experience.
                                    </p>
                                    <button class="box-btn s-b" (click)="navigateToMoreInfo()">Know More</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="card service-card">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <h3>Security & Compliance</h3>
                                </li>
                                <li class="list-group-item-img">
                                    <img class="icon-service img-fluid" src="../../../../assets/images/security.png" alt="Security"/>
                                </li>
                                <li class="list-group-item-content">
                                    <p>
                                        At SecureBridge we specialize in providing end-to-end security and compliance services that help your firm meet and maintain essential standards such as SOC2, GDPR, ISO 27001, CIS and many more.
                                    </p>
                                    <button class="box-btn s-b" (click)="navigateToMoreInfo()">Know More</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
</ng-container>
<ng-container *ngIf="showServicesMoreInfo">
    <app-services-more-info></app-services-more-info>
  </ng-container>

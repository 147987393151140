import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { GragmentServiceService } from "src/app/gragment-service.service";
@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class NavbarComponent implements OnInit {
    location: any;
    navbarClass: any;
    isMobile: boolean;

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
        document
            .querySelector(".navbar-collapse.mean-menu")
            .classList.toggle("open");
    }

    constructor(
        private router: Router,
        location: Location,
        private fragmentService: GragmentServiceService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this.router.url;
                if (this.location == "/home-three") {
                    this.navbarClass = "navbar-area three";
                } else {
                    this.navbarClass = "navbar-area";
                }
            }
        });
    }
    scrollToFragment(fragment: string) {
        this.fragmentService.scrollToFragment(fragment);
    }
    checkScreenWidth() {
        this.isMobile = window.innerWidth <= 991;
    }
    ngOnInit(): void {
        this.checkScreenWidth();
        window.addEventListener("resize", () => {
            this.checkScreenWidth();
        });
    }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

declare var calendar: any;

@Component({
  selector: 'app-scheduling-button',
  templateUrl: './scheduling-button.component.html',
  styleUrls: ['./scheduling-button.component.scss']
})
export class SchedulingButtonComponent implements OnInit {
  @ViewChild('schedulingContainer', { static: false }) schedulingContainer: ElementRef;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {  
  }

  openCalendarPopup(): void {
    const calendarURL = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1kcKwdCUKX5rd5QV9vQtEhs-P8hcQdeV0L5vltVAXZbk5Sx5wdRg7GojHGeYL6pMUp7ZaX89ZQ?gv=true';
    const popupWidth = 800;
    const popupHeight = 600;
    const left = (window.innerWidth / 2) - (popupWidth / 2);
    const top = (window.innerHeight / 2) - (popupHeight / 2);
    window.open(calendarURL, 'calendarPopup', `width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`);
  }
}

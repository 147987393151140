import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { Aboutus2Component } from "./components/pages/aboutus2/aboutus2.component";
import { Services2Component } from "./components/pages/services2/services2.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { AppComponent } from "./app.component";
import { ServicesMoreInfoComponent } from "./services-more-info/services-more-info.component";
import { MainPageComponent } from "./components/pages/main-page/main-page.component";
const routes: Routes = [
    { path: "", component: MainPageComponent },
    { path: "", component: Aboutus2Component },
    { path: "services2", component: Services2Component },
    { path: "", component: ContactComponent },
    { path: "error", component: ErrorComponent },
    { path: "principal", component: AppComponent },
    { path: 'services-more-info', component: ServicesMoreInfoComponent },

    // Here add new pages component

    { path: "**", component: ErrorComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AccordionModule } from "ngx-accordion";
import { TabsModule } from 'ngx-tabset';
import { StickyNavModule } from 'ng2-sticky-nav';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { Aboutus2Component } from './components/pages/aboutus2/aboutus2.component';
import { Services2Component } from './components/pages/services2/services2.component';
import { ChangeDetectorRef } from '@angular/core';
import { ServicesMoreInfoComponent } from './services-more-info/services-more-info.component';
import { MainPageComponent } from './components/pages/main-page/main-page.component';
import { SchedulingButtonComponent } from './scheduling-button/scheduling-button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormComponent } from './form/form.component';
import { ReactiveFormsModule } from '@angular/forms'; // <-- Import this
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [
    AppComponent, 
    NavbarComponent,
    FooterComponent,
    HomeOneComponent,
    ErrorComponent,
    ContactComponent,
    Aboutus2Component,
    Services2Component,
    ServicesMoreInfoComponent,
    MainPageComponent,
    SchedulingButtonComponent,
    FormComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    AccordionModule,
    BrowserAnimationsModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule ,
    HttpClientModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

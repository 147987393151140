<div class="form-container">
    <h1 class="form-title"><img class="logo" src="../../assets/images/Secure Bridge/Asset 1.png" alt="Logo">Contact Us</h1>
    <ng-container *ngIf="!submitted; else messageTemplate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <label for="name">Name:</label>
      <input id="name" type="text" formControlName="name">

      <label for="phone">Phone Number:</label>
      <input id="phone" type="number" formControlName="phone">

      <label for="email">Email:</label>
      <input id="email" type="email" formControlName="email">
  
      <label for="companySize">Company Size:</label>
      <select id="companySize" formControlName="companySize">
        <option *ngFor="let size of companySizes" [value]="size">{{ size }}</option>
      </select>
      <label>Interested Products:</label>
<div formArrayName="interestedProducts">
  <table>
    <tbody>
      <tr *ngFor="let product of interestedProducts; let i = index">
        <td style="padding: 0; width: 1%;">
          <input type="checkbox" [value]="product" (change)="onProductChange(i, $event)" style="margin-right: 5px;" id="product-{{i}}">
        </td>
        <td style="padding: 0;">
          <label for="product-{{i}}" style="font-weight: normal;">{{ product }}</label>
        </td>
      </tr>
    </tbody>
  </table>
</div>
      <label for="tellUsMore">Tell us more:</label>
      <textarea id="tellUsMore" rows="4" formControlName="tellUsMore"></textarea>
  
      <button class="box-btn s-b" type="submit">Submit</button>
    </form>
</ng-container>
<ng-template #messageTemplate>
  <p class="success-message">Thank you for contacting us!<br> We will get back to you shortly.</p>
</ng-template>
  </div>
  
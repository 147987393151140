import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
})
export class FormComponent {
  submitted: boolean = false;
  form: FormGroup;

  companySizes = [
    '0-100',
    '100-500',
    '500-1000',
    '1000+'
  ];

  interestedProducts = [
    'Identity & Access Management',
    'Endpoint Management',
    'Security & Compliance'
  ];

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10,}$')]],
      companySize: ['', Validators.required],
      interestedProducts: this.formBuilder.array([], Validators.required), // Initialize with an empty FormArray
      tellUsMore: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendToGoogleSheet(this.form.value).subscribe(
        response => {
          console.log("Data sent to Google Sheet successfully", response);
          this.submitted = true;
          this.form.reset();
        },
        error => {
          console.error("Failed to send data to Google Sheet", error);
          this.submitted = false;
          console.error('Failed to send data to Google Sheet. Please try again.');
        }
      );
    } else {
      let errorMsg = 'Please fix the following issues:\n';
  
      const controls = this.form.controls;
      if (controls.name.errors) {
        errorMsg += '\n- Invalid Name';
      }
      if (controls.email.errors) {
        errorMsg += '\n- Invalid Email';
      }
      if (controls.phone.errors) {
        errorMsg += '\n- Invalid Phone Number';
      }
      if (controls.companySize.errors) {
        errorMsg += '\n- Company Size not selected';
      }
      if (controls.interestedProducts.errors) {
        errorMsg += '\n- No Interested Product selected';
      }
      if (controls.tellUsMore.errors) {
        errorMsg += '\n- Tell us more field is empty';
      }
  
      Object.keys(controls).forEach(field => {
        const control = this.form.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
  
      alert(errorMsg);
    }
  }

  onProductChange(index: number, event: any) {
    const interestedProductsArray = this.form.get('interestedProducts') as FormArray;
    if (event.target.checked) {
      interestedProductsArray.push(new FormControl(this.interestedProducts[index]));
    } else {
      const idx = interestedProductsArray.controls.findIndex(control => control.value === this.interestedProducts[index]);
      if (idx !== -1) {
        interestedProductsArray.removeAt(idx);
      }
    }
  }

  sendToGoogleSheet(formData: any) {
    const scriptURL = environment.scriptUrl; // Replace with your script web app URL
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const body = new URLSearchParams(formData).toString();
    return this.http.post(scriptURL, body, { headers });
  }
}

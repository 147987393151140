import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services2',
  templateUrl: './services2.component.html',
  styleUrls: ['./services2.component.scss']
})
export class Services2Component implements OnInit {
  showServices2: boolean = true;
  showServicesMoreInfo: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  
  navigateToMoreInfo() {
    this.showServices2 = false;
    this.showServicesMoreInfo = true;
  }
}


import { Component, AfterViewInit } from "@angular/core";

declare global {
    interface Window {
        Calendly: any;
    }
}

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements AfterViewInit {
    REFRESH: boolean = false;
    ngAfterViewInit(): void {
        window.Calendly.initInlineWidget({
            url: "https://calendly.com/securebridge/30min",
            parentElement: document.querySelector(".calendly-inline-widget"),
        });
    }

    ngOnInit(): void {


}
}

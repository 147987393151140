<section class="contact-area">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6">
                <app-form></app-form>
    <!-- Google Calendar Appointment Scheduling begin -->
    <!-- <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1kcKwdCUKX5rd5QV9vQtEhs-P8hcQdeV0L5vltVAXZbk5Sx5wdRg7GojHGeYL6pMUp7ZaX89ZQ?gv=true" style="border: 0" width="100%" height="650" frameborder="0"></iframe> -->
    <!-- end Google Calendar Appointment Scheduling  -->
            </div>

            <div class="col-lg-6">          
<div class="maps-content">
    <iframe width="80%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=393&amp;hl=en&amp;q=15851%20Dallas%20Pkwy%20Addison+(Headquarters)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
</div>
            </div>

            </div>
        </div>
</section>

import { Component } from '@angular/core';

@Component({
  selector: 'app-services-more-info',
  templateUrl: './services-more-info.component.html',
  styleUrls: ['./services-more-info.component.scss']
})
export class ServicesMoreInfoComponent {
  

}

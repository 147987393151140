<section class="caoursel" >
  <div class="container custom-container">
  <div id="carouselExampleIndicators" class="carousel slide custom-carousel" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <!-- Endpoint Management section -->
        <section class="feature">
          <!-- endpoint section code -->
          <div class="content-container">
            <div class="text-container">
              <h1>Identity & Access Management</h1>
              
              <p><img class="icon-services img-fluid" src="../../../../assets/images/identity.png" alt="Identity" width="100px" />At SecureBridge, we specialize in offering cutting-edge Identity & Access Management solutions designed specifically to meet the specific requirements of startups and medium-sized enterprises that are having difficulty competing in the technological sector. We provide a thorough and integrated approach to identity management by leveraging our in-depth knowledge of and expertise in Okta solutions.
            
                  <br>Through Single Sign-On and Multi-Factor Authentication, our services effortlessly link Okta with top applications like Kandji, Jira, Confluence, and Slack, giving organizations streamlined, secure access. This integration improves teamwork, efficiency, and communication, allowing your business to flourish in the fast-paced digital environment of today.
                  
                  <br>We're happy to provide unmatched service and support in the Identity & Access Management industry as an Okta-certified partner. You can rely on SecureBridge to provide a tailored, secure, and effective solution that advances your organization.</p>
              <div class="service-btn">
                <app-scheduling-button ></app-scheduling-button>
            </div>
            <!-- <div class="partners-section">
              <img src="../../assets/images/Secure Bridge/icons-servrices/Frame 1.png" alt="Partner Image" class="partner-image" />
            </div> -->
          </div>
            
          </div>
        </section>
      </div>
      <div class="carousel-item">
        <!-- Identity & Access Management section -->
        <section class="feature">
          <!-- endpoint section code -->
          <div class="content-container">
            <div class="text-container">
              <h1>Endpoint Management</h1>
              
              <p><img class="icon-services img-fluid" src="../../../../assets/images/endpoint.png" alt="Endpoint" width="100px"/>Mobile device management (MDM) has become increasingly important in today's digital landscape. With more employees working remotely and using their own devices for work, it's critical to ensure that your organization's data is secure. That's where SecureBridge’s MDM services come in.<br>
                  Our MDM solutions allow you to manage and secure the devices used by your employees to access company data and resources. With our solutions, you can enforce security policies, remotely wipe data from lost or stolen devices, and ensure that only authorized users can access sensitive information. This reduces the risk of data leaks or breaches and helps protect your organization's reputation and bottom line.<br>
                  In addition to its security and efficiency benefits, MDM can also help ensure that your organization is in compliance with software licensing and patching requirements. You can easily track which devices are running which software, ensure that all devices are up-to-date with the latest patches, and ensure that all software is properly licensed. This reduces the risk of non-compliance and helps protect your organization from legal action and reputational damage.</p>
                  
                  <div class="service-btn">
                <app-scheduling-button ></app-scheduling-button>
            </div>
            <!-- <div class="partners-section">
              <img src="../../assets/images/Secure Bridge/icons-servrices/Frame 1.png" alt="Partner Image" class="partner-image" />
            </div> -->
          </div>
            
          </div>
        </section>
      </div>
      <div class="carousel-item">
        <!-- Security and Compliance section -->
        <section class="feature">
          <!-- endpoint section code -->
          <div class="content-container">
            <div class="text-container">
              <h1>Security & Compliance</h1>
              <p><img class="icon-services img-fluid" src="../../../../assets/images/security.png" alt="Security" width="100px"/>Mobile device management (MDM) has become increasingly important in today's digital landscape. With more employees working remotely and using their own devices for work, it's critical to ensure that your organization's data is secure. That's where SecureBridge’s MDM services come in.<br>
                Our MDM solutions allow you to manage and secure the devices used by your employees to access company data and resources. With our solutions, you can enforce security policies, remotely wipe data from lost or stolen devices, and ensure that only authorized users can access sensitive information. This reduces the risk of data leaks or breaches and helps protect your organization's reputation and bottom line.<br>
                In addition to its security and efficiency benefits, MDM can also help ensure that your organization is in compliance with software licensing and patching requirements. You can easily track which devices are running which software, ensure that all devices are up-to-date with the latest patches, and ensure that all software is properly licensed. This reduces the risk of non-compliance and helps protect your organization from legal action and reputational damage.</p>
              <div class="service-btn">
                <app-scheduling-button ></app-scheduling-button>
            </div>
            <!-- <div class="partners-section">
              <img src="../../assets/images/Secure Bridge/icons-servrices/Frame 1.png" alt="Partner Image" class="partner-image" />
            </div> -->
          </div>
            
          </div>
        </section>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
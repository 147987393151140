import { Component } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { GragmentServiceService } from './gragment-service.service';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    faCoffee = faCoffee;
    location: any;
    routerSubscription: any;
    

    constructor(
        private router: Router,
        private fragmentService: GragmentServiceService
    ) {}

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
    ngAfterViewInit() {

        // subscribe to the NavigationEnd event
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            const fragment = this.fragmentService.getCurrentFragment();
            if (fragment) {
              this.fragmentService.scrollToFragment(fragment);
            } else {
              window.scrollTo(0, 0);
            }
          }
        });
        const fragment = this.fragmentService.getCurrentFragment();
        if (fragment) {
          this.fragmentService.scrollToFragment(fragment);
        }

}
// scroll to the fragment when the page is loaded

scrollToFragment(fragment: string) {
    this.fragmentService.scrollToFragment(fragment);

  }
}

<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Discover Deploy Secure</h1>
                            <p>We offer businesses in a variety of industries with top-notch Identity and Access Management, Endpoint Management, Security, and Compliance solutions. We are dedicated to assisting businesses secure their IT infrastructure so they can boldly embrace digital transformation. </p>
                            <div class="banner-btn">
                                <a routerLink="/contact" (click)="scrollToFragment ('contact')" class="box-btn">Contact Us</a>
                                <a routerLink="/services"
                                [fragment]="'services'"
                                (click)="scrollToFragment('services')" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/identity.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/connect.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/internet.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/lock.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/shield.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/cloud.png" alt="shape"></div>
    </div>
</section>


<div class="{{ navbarClass }}">
    <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow">
        <div class="container">
            <nav
                class="navbar navbar-expand-lg navbar-light"
                [class.active]="classApplied"
            >
                <a class="navbar-brand" routerLink="/">
                    <img class="navbar-logo" src="../../../../assets/images/Secure Bridge/Asset 14.png" alt="logo" (click)="scrollToFragment ('home')" />
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    (click)="toggleClass()"
                >
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <div *ngIf="!isMobile">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/principal"
                                    [fragment]="'aboutus'"
                                    (click)="scrollToFragment('aboutus')"
                                    >About Us</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/services"
                                    [fragment]="'services'"
                                    (click)="scrollToFragment('services')"
                                    >Services</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/contact"
                                    [fragment]="'contact'"
                                    (click)="scrollToFragment('contact')"
                                    >Contact Us</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="isMobile">
                        <ul class="navbar-nav mr-auto" *ngIf="classApplied">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/principal"
                                    [fragment]="'aboutus'"
                                    (click)="scrollToFragment('aboutus')"
                                    >About Us</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/services"
                                    [fragment]="'services'"
                                    (click)="scrollToFragment('services')"
                                    >Services</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    routerLink="/contact"
                                    [fragment]="'contact'"
                                    (click)="scrollToFragment('contact')"
                                    >Contact Us</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>



<section class="aboutus">
  <div class="d-table">
    <div class="d-table-cell">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class=" img col-lg-6">
                    <div class="aboutus-img">
                        <img src="assets/images/choose-img.png" alt="banner-img" />

                    </div>
                </div>
                <div class="col-lg-6 text">
                    <div class="aboutus-content">
                        <h1>About Us</h1>
                        <p>Strong cybercrime solutions, such as Endpoint Management & Security, Identity & Access Management (IAM), and Security & Compliance services, are SecureBridge's area of expertise. Together with you, our team of experts develops specialized strategies for safeguarding your digital assets. <BR> Through compliance assistance for standards like SOC2, GDPR, ISO 27001, and more, we assist in navigating complexities and maintaining a strong security posture. Trust SecureBridge to strengthen your IT infrastructure.</p>
                        <div class="banner-btn">
                            <!-- <a href="https://calendar.app.google/JhyDGepNB1V479Qo9"  class="box-btn" >Contact Us</a> -->
                            <app-scheduling-button ></app-scheduling-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
